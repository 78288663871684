import React, { Component } from 'react';
import Home from './Home';
import Portfolio from './Portfolio';

class index extends Component {
    render() {
        return (
            <>
                <Home />
                <Portfolio />
            </>
        );
    }
}

export default index;